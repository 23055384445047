// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import endPoint from '../../../../apiConfig'


const url = endPoint


export const getCustomer = createAsyncThunk('customer', async params => {
    let obj = {}
    let path = `api/v1/customer/submit?page=${params.page}&perPage=${params.perPage}`

    if (params?.name || params?.mobile || params?.bank_id || params?.type_id || params?.startdate || params?.enddate) {
        for (const [key, value] of Object.entries(params)) {
            if (value) {
                obj[key] = value
            }
        }
        path = path + '&' + new URLSearchParams(obj)
    }
    const response = await fetch(url + path, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + params.token
        }
    }).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            return response
        }
    })
    return response
})

export const getBank = createAsyncThunk('bank', async params => {
    let path = `api/v1/banks`
    const response = await fetch(url + path, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }

    }).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            return response
        }
    })
    return response
})

export const getCustomerDetail = createAsyncThunk('customerDetail', async params => {
    let path = 'api/v1/customer/submit/' + params.id
    const response = await fetch(url + path, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + params.token
        }
    }).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            throw new Error('Something went wrong');
        }
    })
    if (response) {
        return response
    }
    else {
        return null
    }
})

export const putCustomerDetail = createAsyncThunk('putCustomerDetail', async params => {
    let path = 'api/v1/customer/submit'
    const response = await fetch(url + path, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + params.token
        },
        body: JSON.stringify({
            id: params.id,
            name: params.name,
            mobile: params.mobile,
            confirm_interest_id: params.confirm_interest_id
        })
    }).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            throw new Error('Something went wrong');
        }
    })
    if (response) {
        return response
    }
    else {
        return null
    }
})



export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        userLists: [],
        meta: null,
        searchCriteria: {
            name: null,
            mobile: null,
            bank_id: null,
            type_id: null,
            startdate: null,
            enddate: null
        },
        perpage: {
            pages: [10, 20, 30],
            per: 10,
            page: 1
        },
        types: [
            { label: 'รีไฟแนนซ์', value: 1 },
            { label: 'จำนำเล่ม', value: 2 },
            { label: 'รถมือสอง', value: 3 }
        ],
        banks: [],
        customerDetail: null,
        isRevoke: false,
        reload: false
    },
    reducers: {
        handleSearch: (state, action) => {
            if (action.type === 'dashboard/handleSearch') {
                state.searchCriteria[action.payload.target] = action.payload.value
            }
        },
        clearSearch: (state, action) => {
            if (action.type === 'dashboard/clearSearch') {
                state.searchCriteria.name = null
                state.searchCriteria.mobile = null
                state.searchCriteria.bank_id = null
                state.searchCriteria.type_id = null
                state.searchCriteria.startdate = null
                state.searchCriteria.enddate = null
            }
        },
        isRevokeHandle: (state, action) => {
            if (action.type === 'dashboard/isRevokeHandle') {
                state.isRevoke = action.payload
            }
        },
        perPageHandle: (state, action) => {
            if (action.type === 'dashboard/perPageHandle') {
                state.perpage.per = action.payload
            }
        },
        clearDetail: (state, action) => {
            if (action.type === 'dashboard/clearDetail') {
                state.customerDetail = null
            }
        },
        setReload: (state, action) => {
            if (action.type === 'dashboard/setReload') {
                state.reload = false
            }
        },
        bankHandle: (state, action) => {
            if (action.type === 'dashboard/bankHandle') {
                state.customerDetail.confirm_interest_id = action.payload
            }
        }
    },
    extraReducers: builder => {
        builder.addCase(getCustomer.fulfilled, (state, action) => {
            if (action.type === 'customer/fulfilled') {
                if (action.payload.data && action.payload.meta) {
                    state.userLists = action.payload.data
                    state.meta = action.payload.meta
                }
                else {
                    state.isRevoke = true
                }

            }
        })
        builder.addCase(getCustomerDetail.fulfilled, (state, action) => {
            if (action.type === 'customerDetail/fulfilled') {
                state.customerDetail = action.payload
            }
        })
        builder.addCase(getBank.fulfilled, (state, action) => {
            if (action.type === 'bank/fulfilled') {
                state.banks = action.payload
            }
        })
        builder.addCase(putCustomerDetail.fulfilled, (state, action) => {
            if (action.type === 'putCustomerDetail/fulfilled') {
                state.reload = true
            }
        })
    }
})

export const { handleSearch, clearSearch, isRevokeHandle, perPageHandle, clearDetail, setReload, bankHandle } = dashboardSlice.actions

export default dashboardSlice.reducer
