// ** Reducers Imports
import main from '../../src/pages/Main/store/index'
import review from '../../src/pages/Review/store/index'
import auth from '../../src/pages/Admin/Login/store/index'
import dashboard from '../../src/pages/Admin/Dashboard/store/index'

const rootReducer = {
  main,
  review,
  auth,
  dashboard
}

export default rootReducer
