// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// import { useDispatch } from 'react-redux'
import endPoint from '../../../apiConfig'


const url = endPoint

export const getBrand = createAsyncThunk('cars/brand', async params => {
    const response = await fetch(url + 'api/v1/cars/brand?' + new URLSearchParams({ vehicle_type_id: params }),).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            throw new Error('Something went wrong');
        }
    })
    if (response.brands) {
        return response.brands
    }
    else {
        return []
    }
})

export const getModel = createAsyncThunk('cars/model', async params => {
    const response = await fetch(url + 'api/v1/cars/model?' + new URLSearchParams({ brand: params.brand , vehicle_type_id: params.vehicle_type_id  }), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    }).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            throw new Error('Something went wrong');
        }
    })
    if (response.models) {
        return response.models
    }
    else {
        return []
    }
})

export const getYear = createAsyncThunk('cars/year', async params => {
    const response = await fetch(url + 'api/v1/cars/year?' + new URLSearchParams({ brand: params.brand, model: params.model , vehicle_type_id: params.vehicle_type_id }), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    }).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            throw new Error('Something went wrong');
        }
    })
    if (response.years) {
        return response.years
    }
    else {
        return []
    }
})

export const getPrice = createAsyncThunk('cars/price', async params => {
    const response = await fetch(url + 'api/v1/cars/price?' + new URLSearchParams({ brand: params.brand, model: params.model, year: params.year, vehicle_type_id: params.vehicle_type_id }), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    }).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            throw new Error('Something went wrong');
        }
    })
    if (response.price) {
        return response.price
    }
    else {
        return []
    }
})

export const mainSlice = createSlice({
    name: 'main',
    initialState: {
        dropDown: {
            brands: [],
            models: [],
            years: []
        },
        price: null,
        selectedValue: {
            type_id: 1,
            vehicle_type_id:1,
            brand: null,
            model: null,
            year: null,
            price: null
            // type_id: 1,
            // vehicle_type_id: 1,
            // brand: 'HONDA',
            // model: 'City S',
            // year: '2020',
            // price: 400000
        }
    },
    reducers: {
        selectedDropdown: (state, action) => {
            if (action.type === 'main/selectedDropdown') {
                state.selectedValue[action.payload.target] = action.payload.value
                if (action.payload.target === 'brand') {
                    state.selectedValue.model = null
                    state.selectedValue.year = null
                    state.dropDown.models = []
                    state.dropDown.years = []
                }
                if (action.payload.target === 'model') {
                    state.selectedValue.year = null
                    state.dropDown.years = []
                }
            }
        },
        setPrice: (state, action) => {
            if (action.type === 'main/setPrice') {
                if (action.payload.target === 'price') {
                    state.selectedValue[action.payload.target] = parseInt(action.payload.value)
                }
            }
        },
        typeHandle: (state, action) => {
            if (action.type === 'main/typeHandle') {
                if (action.payload.target === 'type_id') {
                    state.selectedValue[action.payload.target] = parseInt(action.payload.value)
                }
            }
        },
        vehicleTypeHandle: (state, action) => {
            if (action.type === 'main/vehicleTypeHandle') {
                if (action.payload.target === 'vehicle_type_id') {
                    state.selectedValue[action.payload.target] = parseInt(action.payload.value)
                    state.selectedValue.brand = null
                    state.selectedValue.model = null
                    state.selectedValue.year = null
                    state.dropDown.models = []
                    state.dropDown.years = []
                    state.dropDown.brands = []
                }
            }
        },
    },
    extraReducers: builder => {
        builder.addCase(getBrand.fulfilled, (state, action) => {
            if (action.type === 'cars/brand/fulfilled') {
                state.dropDown.brands = action.payload
            }
        })
        builder.addCase(getModel.fulfilled, (state, action) => {
            if (action.type === 'cars/model/fulfilled') {
                state.dropDown.models = action.payload
            }

        })
        builder.addCase(getYear.fulfilled, (state, action) => {

            if (action.type === 'cars/year/fulfilled') {
                state.dropDown.years = action.payload
            }
        })
        builder.addCase(getPrice.fulfilled, (state, action) => {
            if (action.type === 'cars/price/fulfilled') {
                state.price = action.payload
            }

        })
    }
})

export const { selectedDropdown, setPrice, typeHandle, vehicleTypeHandle } = mainSlice.actions

export default mainSlice.reducer
