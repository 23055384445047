// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// import { useDispatch } from 'react-redux'
import endPoint from '../../../../apiConfig'




const localStorage = require('localStorage')
const url = endPoint


export const postLogin = createAsyncThunk('cars/model', async params => {
    const response = await fetch(url + 'api/v1/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            username: params.username,
            password: params.password
        })
    }).then((response) => {

        return response.json();

    })
    return response
})



export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        auth: JSON.parse(localStorage.getItem('auth_cps')),
        error: {
            isError: false,
            message: null
        }
    },
    reducers: {

    },
    extraReducers: builder => {
        builder.addCase(postLogin.fulfilled, (state, action) => {
            if(action.payload.status === 'error'){
                state.error.isError = true
                state.error.message =  action.payload.message
            }
            if(action.payload.id){
                state.error.isError = false
                state.error.massage =  null
                state.auth = action.payload
                localStorage.setItem('auth_cps',JSON.stringify(action.payload))
            }
           
        })

    }
})

// export const { } = authSlice.actions

export default authSlice.reducer
