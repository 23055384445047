// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// import { useDispatch } from 'react-redux'
import endPoint from '../../../apiConfig'


const url = endPoint

export const getCalculate = createAsyncThunk('cars/calculate', async params => {

    const response = await fetch(url + 'api/v1/cars/calculate', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            brand: params.brand,
            model: params.model,
            year: params.year,
            request_amount: params.request_amount,
            type_id: params.type_id
        })
    }).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            throw new Error('Something went wrong');
        }
    })
    if (response) {
        return response
    }
    else {
        return []
    }
})

export const postSubmit = createAsyncThunk('customer/submit', async params => {

    const response = await fetch(url + 'api/v1/customer/submit', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            name: params.name,
            mobile: params.mobile,
            occupation: params.occupation,
            province: params.province,
            interest_id: params.interest_id,
            income_range: params.income_range,
            request_amount: params.request_amount,
            type_id: params.type_id
        })
    }).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            throw new Error('Something went wrong');
        }
    })
    if (response) {
        console.log(response)
        return response
    }
    else {
        return []
    }
})



export const reviewSlice = createSlice({
    name: 'review',
    initialState: {
        lists: [],
        month: 24,
        dropDown: {
            months: [
                24, 36, 48, 60
            ]
        },
        sort: { label: 'ดอกเบี้ยน้อย-มาก', value: 'interest<' },
        data: [

        ],
        minPrice: null,
        maxPrice: null,
        bankData: [

        ],
        submitData: {
            name: '',
            mobile: '',
            occupation: null,
            province: null,
            interest_id: null,
            income_range: null,
            request_amount: null
        },
        success: false,
        error: false
    },
    reducers: {
        selectedMonth: (state, action) => {
            if (action.type === 'review/selectedMonth') {
                state.month = action.payload
            }
        },
        dropDownHandle: (state, action) => {
            if (action.type === 'review/dropDownHandle') {
                state.submitData[action.payload.target] = action.payload.value
            }
        },
        inputHandle: (state, action) => {
            if (action.type === 'review/inputHandle') {
                state.submitData[action.payload.target] = action.payload.value
            }
        },
        selectedPartner: (state, action) => {
            if (action.type === 'review/selectedPartner') {
                state.submitData.interest_id = action.payload
            }
        },
        setSuccess: (state, action) => {
            if (action.type === 'review/setSuccess') {
                state.success = false
            }
        },
        setSort: (state, action) => {
            if (action.type === 'review/setSort') {
                if (action.payload.value === 'interest<') {
                    state.lists.sort(function (a, b) {
                        return a.interest_rate - b.interest_rate;
                    });
                    state.data.sort(function (a, b) {
                        return a.value - b.value;
                    });
                    state.data.map((e,i) => {
                        return e.partner=`ข้อเสนออันดับที่ ${i+1}`
                    })
                }
                else {
                    
                    state.lists.sort(function (a, b) {
                        return a.interest_rate - b.interest_rate;
                    }).reverse()
                 
                    state.data.sort(function (a, b) {
                        return a.value - b.value;
                    }).reverse()
                    state.data.map((e,i) => {
                        return e.partner=`ข้อเสนออันดับที่ ${i+1}`
                    })
                }
                let bankData = []
                state.data.map((e,i) => {
                    return bankData.push(`ข้อเสนออันดับที่ ${i+1}`)
                })
                state.bankData = bankData
                state.sort = action.payload
            }
        },
    },
    extraReducers: builder => {
        builder.addCase(getCalculate.fulfilled, (state, action) => {
            if (action.type === 'cars/calculate/fulfilled') {
                let bankData = []
                let data = []
                for (let i = 0; i < action.payload.lists.length; i++) {
                    data.push({
                        // partner: action.payload.lists[i].bank.name,
                        partner: `ข้อเสนออันดับที่ ${i+1}`,
                        // [action.payload.lists[i].bank.name]: ((((action.meta.arg.request_amount * (action.payload.lists[i].interest_rate / 100)) * (state.month / 12)) + action.meta.arg.request_amount) / state.month).toFixed(0),
                        [`ข้อเสนออันดับที่ ${i+1}`]: ((((action.meta.arg.request_amount * (action.payload.lists[i].interest_rate / 100)) * (state.month / 12)) + action.meta.arg.request_amount) / state.month).toFixed(0),
                        value: ((((action.meta.arg.request_amount * (action.payload.lists[i].interest_rate / 100)) * (state.month / 12)) + action.meta.arg.request_amount) / state.month).toFixed(0)
                    })

                }
                if (state.sort.value === 'interest<') {
                    data.sort(function (a, b) {
                        return a.value - b.value;
                    });
                    action.payload.lists.sort(function (a, b) {
                        return a.interest_rate - b.interest_rate;
                    })
                } else {
                    data.sort(function (a, b) {
                        return a.value - b.value;
                    }).reverse()
                    action.payload.lists.sort(function (a, b) {
                        return a.interest_rate - b.interest_rate;
                    }).reverse()
                }
                data.map((e,i) => {
                    // return bankData.push(e.partner)
                    return bankData.push( `ข้อเสนออันดับที่ ${i+1}`)
                   
                })
                // state.minPrice = action.payload.min_request_available
                // state.maxPrice = action.payload.max_request_available
                state.minPrice = 10000
                state.maxPrice = action.payload.estimate_car_price > 500000 ? 500000 : action.payload.estimate_car_price
                state.lists = action.payload.lists
                state.data = data
                state.bankData = bankData
            }

        })
        builder.addCase(postSubmit.fulfilled, (state, action) => {
            if (action.type === 'customer/submit/fulfilled') {
                if (action?.payload?.id) {
                    state.success = true
                }
                else {
                    state.success = false
                    state.error = true
                    state.submitData.name = ''
                    state.submitData.mobile = ''
                    state.submitData.occupation = null
                    state.submitData.province = null
                    state.submitData.interest_id = null
                    state.submitData.income_range = null
                }
            }

        })

    }
})

export const { selectedMonth, dropDownHandle, inputHandle, selectedPartner, setSuccess, setSort } = reviewSlice.actions

export default reviewSlice.reducer
